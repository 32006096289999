import React from 'react'

export default function work() {
    return (
        <div className=''>
            <video loop autoPlay muted className='video w-full'>
                <source src='/assets/images/BlueB-Web_1.mp4' type="video/mp4"></source>
            </video>
        </div>
    )
}
